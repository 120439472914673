import ApiService from '../../service/ApiService';
import configResponse from '../../service/configResponse';

export const CLEAR_DATA = 'clearData';
const state = {
  orderNumber: null,
  paymentData: null,
  alipayPaymentData: null,
  fpsPaymentData: null,
  balanceOrderData: null,
};

const getters = {
  getOrderNumber: (state) => state.orderNumber,
  getPaymentData: (state) => state.paymentData,
  getPaymentDefaultData: (state) => state.paymentDefaultData,
  getAlipayPaymentData: (state) => state.alipayPaymentData,
  getFpsPaymentData: (state) => state.fpsPaymentData,
  getBalanceOrderData: (state) => state.balanceOrderData,
};

const mutations = {
  updateOrderNumber(state, payload) {
    state.orderNumber = payload;
  },
  updatePaymentData(state, payload) {
    state.paymentData = payload;
  },
  updateAlipayPaymentData(state, payload) {
    state.alipayPaymentData = payload;
  },
  updateFpsPaymentData(state, payload) {
    state.fpsPaymentData = payload;
  },
  updateBalanceOrderData(state, payload) {
    state.balanceOrderData = payload;
  },
  updatePaymentDefaultData(state, payload) {
    state.paymentDefaultData = payload;
  },
  clearData(state) {
    sessionStorage.removeItem('infotour');
    sessionStorage.removeItem('booking_deposit');
    sessionStorage.removeItem('payment_status');
    sessionStorage.removeItem('booking_total');
    sessionStorage.removeItem('dateOfTourSeleted');
    sessionStorage.removeItem('info_booking');
    sessionStorage.removeItem('booking_balance');
    sessionStorage.removeItem('timeline');
    sessionStorage.removeItem('balance_id');
    state.orderNumber = null;
    state.qfPaymentData = null;
    state.alipayPaymentData = null;
    state.fpsPaymentData = null;
    state.balanceOrderData = null;
  },
};

const actions = {
  fetchOrderNumber({ commit }, balanceId) {
    return new Promise((resolve, reject) => {
      ApiService.get('/orders', balanceId)
        .then((res) => {
          commit('updateBalanceOrderData', res.data.data);
          resolve();
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  createOrderNumberData({ commit }, param) {
    return new Promise((resolve, reject) => {
      ApiService.post('/orders/createOrderNumber', param)
        .then((res) => {
          commit('updateOrderNumber', res.data.data.orderNumber);
          resolve();
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  clear({ commit }) {
    commit(CLEAR_DATA);
  },
  updateOrderNumberData({ commit }, param) {
    return new Promise((resolve, reject) => {
      ApiService.post('/orders', param)
        .then((res) => {
          var responseData = res.data;
          if (responseData.statusCode == 200) {
            commit(CLEAR_DATA);
            setTimeout(function () {
              window.location.assign(
                window.location.origin + '/#/success/true',
              );
            }, 500);
          }
          resolve();
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  createPaymentDefault({ commit }, data) {
    return new Promise((resolve, reject) => {
      ApiService.post('/payments', data)
        .then((res) => {
          commit('updatePaymentDefaultData', res.data.data);
          resolve();
        })
        .catch(({ response }) => {
          configResponse(response);
          reject();
        });
    });
  },
  
  visaPaymentDefault({ commit }, data) {
    return new Promise((resolve, reject) => {
      ApiService.post('/fuson-order/toPay/visa', data)
        .then((res) => {
          commit('');
          resolve(res.data.returnData);
        })
        .catch(({ response }) => {
          console.log(response)
          configResponse(response);
          reject(response.data);
        });
    });
  },
  unifiedQfpayDefault({ commit }, data) {
    return new Promise((resolve, reject) => {
      ApiService.post('/fuson-order/toPay/unifiedQfpay', data)
        .then((res) => {
          commit('');
          resolve(res.data.returnData);
        })
        .catch(({ response }) => {
          configResponse(response);
          reject(response.data);
        });
    });
  },
  payBack({ commit }, data) {
    return new Promise((resolve, reject) => {
      ApiService.post('/fuson-order/toPay/callBack',data)
        .then((res) => {
          resolve(res.data.returnData);
        })
        .catch(({ response }) => {
          configResponse(response);
          reject();
        });
    });
  },
  callApiPayment({ commit }, data) {
    return new Promise((resolve, reject) => {
      ApiService.get(`/checkout/${data.paymentType}/${data.paymentId}`)
        .then((res) => {
          commit('updatePaymentData', res.data.data);
          resolve();
        })
        .catch(({ response }) => {
          configResponse(response);
          reject();
        });
    });
  },
  callAlipayPayment({ commit }, data) {
    return new Promise((resolve, reject) => {
      ApiService.post('/qfpayment/payment', data)
        .then((res) => {
          commit('updateAlipayPaymentData', res.data.data);
          resolve();
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  callFpsPayment({ commit }, data) {
    return new Promise((resolve, reject) => {
      ApiService.post('/qfpayment/payment', data)
        .then((res) => {
          commit('updateFpsPaymentData', res.data.data);
          resolve();
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  callCreditCardPayment({ commit }, data) {
    return new Promise((resolve, reject) => {
      ApiService.post('/checkout/card', data)
        .then((res) => {
          commit(CLEAR_DATA);
          resolve(res);
        })
        .catch(({ response }) => {
          configResponse(response);
          reject();
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
